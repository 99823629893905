<script>
import LayoutProfile from "@/views/layouts/layoutProfile.vue";

export default {
	name: "Help",
	components: {LayoutProfile}
}
</script>

<template>
	<layoutProfile>
		<h4 class="px-4 pt-4 mb-0">Help</h4>
		<div class="p-4 user-profile-desc">
			<b-card no-body class="border custom-accordion">
				<b-card-header>
					<h5 class="font-size-15 m-0">Information</h5>
				</b-card-header>
				<b-card-body>
					<div class="py-3">
						<h5 class="font-size-13 mb-0">
							<router-link
								to="faq"
								class="nav-link"
								role="tab"
							>
								{{ $t("chat.tabs.settings.help.faqs") }}
							</router-link>
						</h5>
					</div>
					<div class="py-3 border-top">
						<h5 class="font-size-13 mb-0">
							<router-link
								to="contact-us"
								class="nav-link"
								role="tab"
							>
								{{ $t("chat.tabs.settings.help.contact") }}
							</router-link>
						</h5>
					</div>
					<div class="py-3 border-top">
						<h5 class="font-size-13 mb-0">
							<router-link
								to="terms"
								class="nav-link"
								role="tab"
							>
								Terms of Service
							</router-link>
						</h5>
					</div>
					<div class="py-3 border-top">
						<h5 class="font-size-13 mb-0">
							<router-link
								to="privacy"
								class="nav-link"
								role="tab"
							>
								Privacy Policy
							</router-link>
						</h5>
					</div>
				</b-card-body>
			</b-card>
		</div>
	</layoutProfile>
</template>

<style scoped lang="scss">

</style>